<template>
  <div>
    <Loader />
    <Customizer @onLogo="changeLogo" />
    <section class="sign-in-page">
      <b-container id="sign-in-page-box" class="p-0">
        <div class="bg-white form-container sign-in-container">
          <div class="sign-in-page-data">
            <div class="sign-in-from w-100 m-auto">
              <router-view></router-view>
            </div>
          </div>
        </div>
        <div class="overlay-container">
          <div class="overlay">
            <div class="overlay-panel overlay-right">
              <a class="sign-in-logo mb-5" href="#"><img src="../../assets/images/Logo-C9lab eyeWhite.png"  class="img-fluid w-100 h-100" alt="logo"></a>
            </div>
          </div>
        </div>
      </b-container>
    </section>
  </div>
</template>
<script>
import Loader from '../../components/core/loader/Loader'
import logo from '../../assets/images/logo-white.png'
import { core } from '../../config/pluginInit'
import bgImage from '../../assets/images/login/mail.png'
import Customizer from '../Components/Customizer'

export default {
  name: 'AuthLayout',
  components: {
    Loader,
    Customizer
  },
  mounted () {
    core.index()

    // const signUpButton = document.getElementById('signUp')
    // const signInButton = document.getElementById('signIn')
    // const container = document.getElementById('sign-in-page-box')

    // signUpButton.addEventListener('click', () => container.classList.add('right-panel-active')
    // )
    // signInButton.addEventListener('click', () => container.classList.remove('right-panel-active')
    // )
  },
  data () {
    return {
      slickOptions: {
        slidesToShow: 1,
        arrows: false
      },
      logo: logo,
      bgImageURL: bgImage
    }
  },
  methods: {
    changeLogo (e) {
      this.logo = e
    }
  }
}
</script>
<style>
  @import url("../../assets/css/custom.css");
</style>
